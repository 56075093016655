import { inject }                        from 'aurelia-framework';
import { ControllingEntitiesRepository } from 'modules/entities/controlling-entities/services/repository';
import { ControllingEntity }             from 'modules/entities/models/controlling-entity';

@inject(ControllingEntitiesRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param controllingEntitiesRepository
     */
    constructor(controllingEntitiesRepository) {
        this.controllingEntitiesRepository = controllingEntitiesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {ControllingEntity}
     */
    model() {
        let model = new ControllingEntity();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns the data form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.name = {
            type:       'text',
            key:        'name',
            label:      'form.field.designation',
            size:       12,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.district = {
            type:       'text',
            key:        'district',
            label:      'form.field.district',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.county = {
            type:       'text',
            key:        'county',
            label:      'form.field.county',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.parish = {
            type:       'text',
            key:        'parish',
            label:      'form.field.parish',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.address = {
            type:       'text',
            key:        'address',
            label:      'form.field.address',
            size:       12,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.zip_code = {
            type:       'text',
            key:        'zip_code',
            label:      'form.field.zip-code',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.locality = {
            type:       'text',
            key:        'locality',
            label:      'form.field.locality',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.phone_number = {
            type:       'text',
            key:        'phone_number',
            label:      'form.field.phone-number',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.mobile_number = {
            type:       'text',
            key:        'mobile_number',
            label:      'form.field.mobile-number',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.fax_number = {
            type:       'text',
            key:        'fax_number',
            label:      'form.field.fax-number',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.email = {
            type:       'email',
            key:        'email',
            label:      'form.field.email',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.tax_identification_number = {
            type:       'text',
            key:        'tax_identification_number',
            label:      'form.field.tax-number',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.status = {
            type:       'text',
            key:        'status',
            label:      'form.field.status',
            size:       4,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        return [
            [this.name],
            [this.district, this.county, this.parish],
            [this.address],
            [this.zip_code, this.locality],
            [this.phone_number, this.mobile_number, this.fax_number],
            [this.email, this.tax_identification_number, this.status],
        ];
    }

    /**
     * Returns the responsibles form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    responsiblesSchema(viewModel) {
        this.responsibles = {
            type:         'duallistbox',
            key:          'responsibles',
            label:        'form.field.responsibles-to-contact',
            size:         12,
            remoteSource: this.controllingEntitiesRepository.getUsers.bind(this.controllingEntitiesRepository, viewModel.model.id),
            required:     false,
            attributes:   {
                disabled: true,
            },
        };

        return [
            [this.responsibles],
        ];
    }

    /**
     * Returns the settings form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    settingsSchema(viewModel) {
        this.periodic_inspection_fee = {
            type:       'number',
            key:        'periodic_inspection_fee',
            label:      'form.field.periodic-inspection-fee(€)',
            size:       3,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.extraordinary_inspection_fee = {
            type:       'number',
            key:        'extraordinary_inspection_fee',
            label:      'form.field.extraordinary-inspection-fee(€)',
            size:       3,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.reinspection_fee = {
            type:       'number',
            key:        'reinspection_fee',
            label:      'form.field.reinspection-fee(€)',
            size:       3,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.sealing_fee = {
            type:       'number',
            key:        'sealing_fee',
            label:      'form.field.sealing-fee(€)',
            size:       3,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        return [
            [this.periodic_inspection_fee, this.extraordinary_inspection_fee, this.reinspection_fee, this.sealing_fee],
        ];
    }

    /**
     * Returns the buttons form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    buttonsSchema(viewModel) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            hidden:     viewModel.appContainer.authenticatedUser.belongsToControllingEntity(false),
            action:     () => viewModel.redirectToRoute('entities.controlling-entities.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon: {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
