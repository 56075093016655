import { inject }               from 'aurelia-framework';
import { BaseFilterFormSchema } from 'resources/classes/base-filter-form-schema';
import { DialogService }        from 'aurelia-dialog';
import { EventAggregator }      from 'aurelia-event-aggregator';
import { I18N }                 from 'aurelia-i18n';
import { LocalStorage }         from 'resources/services/local-storage';

@inject(LocalStorage, EventAggregator, I18N, DialogService)
export class FilterFormSchema extends BaseFilterFormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param storage
     * @param eventAggregator
     * @param i18n
     * @param dialogService
     */
    constructor(storage, eventAggregator, i18n, dialogService) {
        super(storage, eventAggregator, i18n, dialogService);
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {{}}
     */
    model(viewModel) {
        return super.filterModel(viewModel, this.modelDefaults);
    }

    /**
     * Returns client data form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.name = {
            type:     'text',
            key:      'name',
            label:    'form.field.designation',
            size:     4,
            required: false,
        };

        this.responsibles = {
            type:     'user-multiselect',
            key:      'responsibles',
            label:    'form.field.responsibles-to-contact',
            size:     4,
            required: false,
        };

        this.status_id = {
            type:     'boolean-statuses-select',
            key:      'status_id',
            label:    'form.field.status',
            size:     4,
            required: false,
        };

        this.searchButton = {
            type:       'submit',
            label:      'form.button.search',
            action:     () => this.eventAggregator.publish('datatable-must-be-reloaded', { listingId: viewModel.listingId, criteria: viewModel.filterModel }),
            attributes: {
                class: 'btn btn-teal filter-submit',
            },
            icon:       {
                attributes: {
                    class: 'icon-search4',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      'form.button.clear',
            action:     () => this.eventAggregator.publish('datatable-filter-must-be-reseted', viewModel.listingId),
            attributes: {
                class: 'btn btn-light filter-reset',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.searchButton,
                this.clearButton,
            ],
        };

        return [
            [this.name, this.responsibles, this.status_id],
            [this.buttons],
        ];
    }

}
