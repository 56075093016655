import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class BaseRepository {

    /**
     * Constructor
     *
     * @param httpClient
     */
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    /**
     * Returns all records
     *
     * @returns {*}
     */
    all() {
        return this.httpClient.get(`${this.baseUrl}/all`);
    }

    /**
     * Returns active records
     *
     * @returns {*}
     */
    active() {
        return this.httpClient.get(`${this.baseUrl}/active`);
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.httpClient.post(`${this.baseUrl}/search`, criteria);
    }

    /**
     * Creates a record
     *
     * @param data
     *
     * @returns {*}
     */
    create(data) {
        return this.httpClient.post(`${this.baseUrl}`, data);
    }

    /**
     * Finds a record
     *
     * @param id
     *
     * @returns {*}
     */
    find(id) {
        return this.httpClient.get(`${this.baseUrl}/${id}`);
    }

    /**
     * Updates a record
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    update(id, data) {
        return this.httpClient.put(`${this.baseUrl}/${id}`, data);
    }

    /**
     * Destroys a record
     *
     * @param id
     *
     * @returns {*}
     */
    destroy(id) {
        return this.httpClient.delete(`${this.baseUrl}/${id}`);
    }

    /**
     * Destroys selected records
     *
     * @param ids
     *
     * @returns {*}
     */
    destroySelected(ids) {
        return this.httpClient.delete(`${this.baseUrl}/destroy-selected`, { ids: ids });
    }

}
