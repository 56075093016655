import { bindable, inject }            from 'aurelia-framework';
import { AppContainer }                from 'resources/services/app-container';
import { BaseListViewModel }           from 'base-list-view-model';
import { Downloader }                  from 'resources/services/downloader';
import { FilterFormSchema }            from 'modules/entities/controlling-agents/filter-form-schema';
import { ControllingAgentsRepository } from 'modules/entities/controlling-agents/services/repository';

@inject(AppContainer, ControllingAgentsRepository, Downloader, FilterFormSchema)
export class ListControllingAgents extends BaseListViewModel {

    listingId = 'entities-controlling-agents-listing';

    @bindable headerTitle    = 'listing.entities.controlling-agents';
    @bindable newRecordRoute = 'entities.controlling-agents.create';
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param downloader
     * @param filterFormSchema
     */
    constructor(appContainer, repository, downloader, filterFormSchema) {
        super(appContainer);

        this.repository       = repository;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
        this.newRecordRoute   = appContainer.authenticatedUser.can(['entities.controlling-agents.manage', 'entities.controlling-agents.create']) ? this.newRecordRoute : null;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'entities.controlling-agents.manage',
            'entities.controlling-agents.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:        'resource.entities.controlling-agent',
            repository:      this.repository,
            show:            {
                action:  true,
                visible: (row) => this.appContainer.authenticatedUser.can(['entities.controlling-agents.manage', 'entities.controlling-agents.view']),
            },
            edit:            {
                action:  true,
                visible: (row) => this.appContainer.authenticatedUser.can(['entities.controlling-agents.manage', 'entities.controlling-agents.edit']),
            },
            destroy:         {
                action:  'entities.controlling-agents.destroy',
                visible: (row) => this.appContainer.authenticatedUser.can(['entities.controlling-agents.manage', 'entities.controlling-agents.delete']),
            },
            actions:         [],
            options:         [
                {
                    label:  'form.button.export-to-excel',
                    icon:   'icon-file-excel',
                    action: () => this.exportListing('agentes_de_fiscalizacao'),
                },
            ],
            buttons:         [],
            selectable:      true,
            destroySelected: this.appContainer.authenticatedUser.can(['entities.controlling-agents.manage', 'entities.controlling-agents.delete']),
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'name',
                    name:  'technician_data.name',
                    title: 'form.field.name',
                },
                {
                    data:  'code',
                    name:  'technician_data.code',
                    title: 'form.field.agent-number',
                },
                {
                    data:  'entity',
                    name:  'entity_data.name',
                    title: 'form.field.controlling-entity',
                },
                {
                    data:  'updater',
                    name:  'updaters.name',
                    title: 'form.field.last-modified-by',
                },
                {
                    data:            'updated_at',
                    name:            'technicians.updated_at',
                    title:           'form.field.last-modified-at',
                    valueConverters: [
                        { name: 'dateTimeFormat' },
                    ],
                },
                {
                    data:  'status',
                    name:  'boolean_statuses.name',
                    title: 'form.field.status',
                    type:  'label',
                },
                {
                    data:       'alerts',
                    title:      '',
                    type:       'stoplight',
                    orderable:  false,
                    searchable: false,
                    color:      (row) => 'text-default',
                    icon:       (row) => row.user_id === null ? '' : 'icon-user',
                    popover:    {
                        visible:         () => true,
                        trigger:         'hover',
                        placement:       'right',
                        contentCallback: (row) => row.user_id === null ? '' : this.appContainer.i18n.tr('messages.associated-with-user'),
                    },
                },
            ],
        };
    }

}
