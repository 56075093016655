import { inject }                                  from 'aurelia-framework';
import { BooleanStatus }                           from 'modules/administration/models/boolean-status';
import { CountiesRepository }                      from 'modules/administration/locations/counties/services/repository';
import { ControllingEntitiesRepository }           from 'modules/entities/controlling-entities/services/repository';
import { ControllingEntity }                       from 'modules/entities/models/controlling-entity';
import { ParishesRepository }                      from 'modules/administration/locations/parishes/services/repository';
import { SingleFormSchema as LocationsFormSchema } from 'modules/administration/locations/reusable/single-form-schema';

@inject(ControllingEntitiesRepository, CountiesRepository, LocationsFormSchema, ParishesRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        status_id: BooleanStatus.ACTIVE,
    };

    /**
     * Constructor
     *
     * @param controllingEntitiesRepository
     * @param countiesRepository
     * @param locationsFormSchema
     * @param parishesRepository
     */
    constructor(controllingEntitiesRepository, countiesRepository, locationsFormSchema, parishesRepository) {
        this.controllingEntitiesRepository = controllingEntitiesRepository;
        this.countiesRepository            = countiesRepository;
        this.locationsFormSchema           = locationsFormSchema;
        this.parishesRepository            = parishesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {ControllingEntity}
     */
    model() {
        let model = new ControllingEntity();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns the data form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.name = {
            type:  'text',
            key:   'name',
            label: 'form.field.designation',
            size:  12,
        };

        this.address = {
            type:     'text',
            key:      'address',
            label:    'form.field.address',
            size:     12,
            required: false,
        };

        this.zip_code = {
            type:     'text',
            key:      'zip_code',
            label:    'form.field.zip-code',
            size:     4,
            required: false,
        };

        this.locality = {
            type:     'text',
            key:      'locality',
            label:    'form.field.locality',
            size:     4,
            required: false,
        };

        this.phone_number = {
            type:     'text',
            key:      'phone_number',
            label:    'form.field.phone-number',
            size:     4,
            required: false,
        };

        this.mobile_number = {
            type:     'text',
            key:      'mobile_number',
            label:    'form.field.mobile-number',
            size:     4,
            required: false,
        };

        this.fax_number = {
            type:     'text',
            key:      'fax_number',
            label:    'form.field.fax-number',
            size:     4,
            required: false,
        };

        this.email = {
            type:     'email',
            key:      'email',
            label:    'form.field.email',
            size:     4,
            required: false,
        };

        this.tax_identification_number = {
            type:     'text',
            key:      'tax_identification_number',
            label:    'form.field.tax-number',
            size:     4,
            required: false,
        };

        this.status_id = {
            type:  'boolean-statuses-select',
            key:   'status_id',
            label: 'form.field.status',
            size:  4,
        };

        return [
            [this.name],
            ...this.locationsFormSchema.schema(viewModel.model, false),
            [this.address],
            [this.zip_code, this.locality],
            [this.phone_number, this.mobile_number, this.fax_number],
            [this.email, this.tax_identification_number, this.status_id],
        ];
    }

    /**
     * Returns the responsibles form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    responsiblesSchema(viewModel) {
        this.responsibles = {
            type:         'duallistbox',
            key:          'responsibles',
            label:        'form.field.responsibles-to-contact',
            size:         12,
            required:     false,
            remoteSource: this.controllingEntitiesRepository.getUsers.bind(this.controllingEntitiesRepository, viewModel.model.id),
        };

        return [
            [this.responsibles],
        ];
    }

    /**
     * Returns the settings form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    settingsSchema(viewModel) {
        this.periodic_inspection_fee = {
            type:       'number',
            key:        'periodic_inspection_fee',
            label:      'form.field.periodic-inspection-fee(€)',
            size:       3,
            attributes: {
                min:  1,
                step: 0.1,
            },
        };

        this.extraordinary_inspection_fee = {
            type:       'number',
            key:        'extraordinary_inspection_fee',
            label:      'form.field.extraordinary-inspection-fee(€)',
            size:       3,
            attributes: {
                min:  1,
                step: 0.1,
            },
        };

        this.reinspection_fee = {
            type:       'number',
            key:        'reinspection_fee',
            label:      'form.field.reinspection-fee(€)',
            size:       3,
            attributes: {
                min:  1,
                step: 0.1,
            },
        };

        this.sealing_fee = {
            type:       'number',
            key:        'sealing_fee',
            label:      'form.field.sealing-fee(€)',
            size:       3,
            attributes: {
                min:  1,
                step: 0.1,
            },
        };

        return [
            [this.periodic_inspection_fee, this.extraordinary_inspection_fee, this.reinspection_fee, this.sealing_fee],
        ];
    }

    /**
     * Returns the buttons form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    buttonsSchema(viewModel) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            hidden:     viewModel.appContainer.authenticatedUser.belongsToControllingEntity(false),
            action:     () => viewModel.redirectToRoute('entities.controlling-entities.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon: {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon: {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
