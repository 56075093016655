import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class BaseSubRepository {

    /**
     * Constructor
     *
     * @param httpClient
     */
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    /**
     * Performs a search given some criteria
     *
     * @param id
     * @param criteria
     *
     * @returns {*}
     */
    search(id, criteria) {
        return this.httpClient.post(`${this.baseUrl}/${id}/${this.resource}/search`, criteria);
    }

    /**
     * Creates a record
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    create(id, data) {
        return this.httpClient.post(`${this.baseUrl}/${id}/${this.resource}`, data);
    }

    /**
     * Finds a record
     *
     * @param id
     *
     * @returns {*}
     */
    find(id) {
        return this.httpClient.get(`${this.baseUrl}/${resource}/${id}`);
    }

    /**
     * Updates a record
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    update(id, data) {
        return this.httpClient.put(`${this.baseUrl}/${this.resource}/${id}`, data);
    }

    /**
     * Destroys a record
     *
     * @param id
     *
     * @returns {*}
     */
    destroy(id) {
        return this.httpClient.delete(`${this.baseUrl}/${this.resource}/${id}`);
    }

    /**
     * Destroys selected records
     *
     * @param ids
     *
     * @returns {*}
     */
    destroySelected(ids) {
        return this.httpClient.delete(`${this.baseUrl}/${this.resource}/destroy-selected`, { ids: ids });
    }

}
